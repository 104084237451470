let module = false;

//let context = require.context('@/overrides/client/store/', true, /\.js/);

let context = require.context('@/', true, /\/overrides\/client\/store\/index\.js/);
context.keys().forEach(key => {
  if ('./overrides/client/store/index.js' === key) {
    module = context(key);
  }
});

if ( ! module) {
  module = require("@/client/store/store.js");
}

export default module.default;


